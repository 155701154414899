<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Par&aacute;metros</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Productos - Sub Grupos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">Administrar Sub Grupos</div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <!--  v-if="'Grupo Crear' in auth.user.permissions" -->
        <Button
          label="Nuevo"
          v-tooltip.top="'Nuevo Sub Grupo'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2 p-button-lg"
          @click="openNuevo"
        />
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtSubGrupos"
        :value="subgrupos"
        :loading="cargando"
        dataKey="id"
        :paginator="true"
        :rows="10"
        :rowHover="true"
        :filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Sub Grupos"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-dblclick="editSubGrupo($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
          </div>
        </template>

        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>

        <Column
          field="id"
          header="CÓDIGO"
          :sortable="true"
          exportHeader="CÓDIGO"
        >
          {{ data.id }}
        </Column>
        <Column
          field="nombre"
          header="NOMBRE/DESCRIPCIÓN"
          exportHeader="NOMBRE/DESCRIPCIÓN"
        >
          {{ data.nombre }}
        </Column>
        <Column field="nombre_grupo" header="NOMBRE GRUPO" exportHeader="GRUPO">
          <template #body="{ data }">
            {{ data.nombre_grupo ? data.nombre_grupo : "Sin Grupo" }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column field="created_at" header="FECHA DE CREACIÓN">
          <template #body="{ data }">
            <!-- aplico formato de fecha -->
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>
    <SubGrupoCreate
      :show="subgrupoDialog"
      :subgrupo="subgrupo"
      @closeModal="ocultarDialog"
      @actualizarListado="cargarSubGrupos"
    >
    </SubGrupoCreate>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import SubGrupoService from "@/service/SubGrupoService";
import { useAuth } from "@/stores";
import SubGrupoCreate from "@/module/subgrupos/SubGrupoCreate.vue";

export default {
  components: {
    SubGrupoCreate,
  },
  data() {
    return {
      subgrupos: [],
      cargando: true,
      subgrupoDialog: false,
      subgrupo: {},
      buscar: {},
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  subGrupoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.subGrupoService = new SubGrupoService();
    this.initBuscar();
  },
  mounted() {
    this.cargarSubGrupos();
  },
  methods: {
    acciones(datos) {
      /* let permisosUsuarioAuth = this.auth.user.permissions; */
      return [
        {
          label: "Editar",
          /* disabled: "Grupo Editar" in permisosUsuarioAuth ? false : true, */
          icon: "pi pi-pencil",
          command: () => {
            this.editSubGrupo(datos);
          },
        },
        {
          label: "Eliminar",
          /* disabled: "Grupo Eliminar" in this.auth.user.permissions ? false : true, */
          icon: "pi pi-trash",
          command: () => {
            this.eliminarSubGrupo(datos.id);
          },
        },
      ];
    },
    cargarSubGrupos() {
      this.subGrupoService.getSubGrupoAll().then((data) => {
        this.subgrupos = data.subgrupos;
        this.cargando = false;
      });
    },
    openNuevo() {
      this.subgrupo = {
        estado: { label: "ACTIVO", value: 1 },
      };
      this.subgrupoDialog = true;
    },
    ocultarDialog() {
      this.subgrupoDialog = false;
      this.enviado = false;
    },
    textoEstado(estado) {
      return estado == 1 ? "ACTIVO" : "INACTIVO";
    },
    editSubGrupo(subgrupo) {
      this.subgrupo = {
        ...subgrupo,
        estado: {
          label: this.textoEstado(subgrupo.estado),
          value: subgrupo.estado,
        },
      };
      this.subgrupoDialog = true;
    },
    eliminarSubGrupo(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea eliminar el Punto de Venta?",
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo eliminar",
        rejectLabel: "No, cancelar",
        accept: () => {
          this.subGrupoService.deleteSubGrupo(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarSubGrupos();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        minute: "2-digit",
        hour: "2-digit",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>